.side-nav-open {
  padding-top: 70px;
  height: 100%;
  width: 240px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.3s;
  background-color: var(--themeWhiteColor);
  border-right: 1px solid rgba(0, 0, 0, 0.075);
  scrollbar-color: #cccccc #f5f5f5 !important;
  scrollbar-width: thin;
}
.side-nav-close {
  padding-top: 70px;
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.3s;
  background-color: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.075);
  scrollbar-color: #cccccc #f5f5f5;
  scrollbar-width: thin !important;
}
.side-nav-open::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.side-nav-open::-webkit-scrollbar-track-piece {
  background-color: #f5f5f5;
}
.side-nav-open::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  border-radius: 4px;
  background: #cccccc;
}
.side-nav-close::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.side-nav-close::-webkit-scrollbar-track-piece {
  background-color: #f5f5f5;
}
.side-nav-close::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  border-radius: 4px;
  background: #cccccc;
}
.content {
  transition: 0.3s;
  margin-top: 90px;
  margin-left: 240px;
}
.content-expand {
  margin-top: 90px;
  transition: 0.3s;
  margin-left: 0;
}

.side-bar-item {
  display: inline-block;
  padding: 12px 5px 12px 5px;
  width: 100% !important;
}
.side-bar-item:hover {
  border-left: 4px solid var(--themeColor);
  transition: 0.2s;
  background-color: var(--lightDeepColor-2);
}
.side-bar-item:hover .side-bar-item-icon {
  color: #363b64;
}
.side-bar-item:hover .side-bar-item-caption {
  color: #363b64;
}
.side-bar-item-active {
  border-left: 4px solid var(--themeColor);
  transition: 0.2s;
  background-color: var(--lightDeepColor-2);
  display: inline-block;
  padding: 12px 5px 12px 5px;
  width: 100% !important;
}
.side-bar-item-icon {
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 500;
  color: var(--themeColor);
}
.side-bar-item-caption {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  margin-left: 5px;
  font-weight: 500;
  color: var(--themeColor);
}

input#priority {
  padding-left: 10px !important;
}

/* Responsive */

@media (max-width: 339.98px) {
  .side-nav-open {
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }
  .side-nav-close {
    width: 180px;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }
  .content {
    width: 100%;
    margin-left: 0;
  }
  .content-expand {
    width: 100%;
    margin-left: 180px;
  }
}

@media (min-width: 340px) and (max-width: 360.98px) {
  .side-nav-open {
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }
  .side-nav-close {
    width: 180px;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }
  .content {
    width: 100%;
    margin-left: 0;
  }
  .content-expand {
    width: 100%;
    margin-left: 180px;
  }
}

@media (min-width: 361px) and (max-width: 575.98px) {
  .side-nav-open {
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }
  .side-nav-close {
    width: 180px;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }
  .content {
    width: 100%;
    margin-left: 0;
  }
  .content-expand {
    width: 100%;
    margin-left: 180px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .side-nav-open {
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }
  .side-nav-close {
    width: 180px;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }
  .content {
    width: 100%;
    margin-left: 0;
  }
  .content-expand {
    width: 100%;
    margin-left: 180px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .side-nav-open {
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }
  .side-nav-close {
    width: 180px;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
  }
  .content {
    width: 100%;
    margin-left: 0;
  }
  .content-expand {
    width: 100%;
    margin-left: 180px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) {
}

button.accordion-button {
  padding: 0;
}

.accordion-button {
  border-left: 4px solid #cb0c9f !important;
  transition: 0.2s;
  background-color: #f3d9ec !important;
  display: inline-block;
  padding: 12px 5px 12px 5px !important;
  width: 100% !important;
}

/* .collapsed {
  background: inherit !important;
  border: inherit !important;
  display: inline-block;
  padding: 12px 5px 12px 5px;
  width: 100% !important;
} */

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27%23344767%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}
