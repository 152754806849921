.top-navbar {
  background: #217346;
  transition: 0.3s;
  margin-left: 220px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.03);
}

.top-navbar-expand {
  background: #217346;
  transition: 0.3s;
  margin-left: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
}

.icon-nav {
  padding: 6px;
  transition: 0.3s;
  color: #606060;
}
.icon-nav:hover {
  transition: 0.3s;
  color: #363b64;
}
.icon-nav:active {
  border-radius: 50%;
  padding: 8px;
  transition: 0.3s;
  color: #363b64;
  background: rgba(0, 0, 0, 0.16);
}
.icon-nav-img {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  object-fit: cover;
  cursor: pointer;
}
.nav-logo {
  width: 40px;
  height: auto;
}

.user-dropdown {
  display: inline-block;
}
.user-dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: 260px;
  right: 0;
  transition: 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  z-index: 1;
  top: 100%;
}
.user-dropdown-divider {
  background: #a2a2a2;
}

.link-item {
  display: inline-block;
  padding: 12px 5px 12px 5px;
  width: 100% !important;
}

.link-item:hover {
  border-left: 4px solid var(--bs-green);
  transition: 0.2s;
}
.link-item:hover .link-item-icon {
  color: #363b64;
}
.link-item:hover .link-item-caption {
  color: #363b64;
}
.link-item-active {
  border-left: 4px solid var(--bs-green);

  padding: 12px 5px 12px 5px;
  width: 100% !important;
  display: inline-block;
}
.link-item-icon {
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 500;
  color: #363b64;
}
.link-item-caption {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  margin-left: 5px;
  font-weight: 500;
  color: #363b64;
}

/* Responsive */
@media (max-width: 339.98px) {
  .top-nav-open {
    margin-left: 0;
  }
  .top-nav-close {
    margin-left: 220px;
  }
}

@media (min-width: 340px) and (max-width: 360.98px) {
  .top-nav-open {
    margin-left: 0;
  }
  .top-nav-close {
    margin-left: 200px;
  }
}

@media (min-width: 361px) and (max-width: 575.98px) {
  .top-nav-open {
    margin-left: 0;
  }
  .top-nav-close {
    margin-left: 200px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .top-nav-open {
    margin-left: 0;
  }
  .top-nav-close {
    margin-left: 200px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .top-nav-open {
    margin-left: 0;
  }
  .top-nav-close {
    margin-left: 200px;
  }
}
